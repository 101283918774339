<template>
    <modal :show="showModal" size="lg" id="modal" ref="modal" @close="onModalClose">
        <template v-slot:header>
            <h6 class="modal-title" id="modalTitle">{{ title }}</h6>
        </template>
        <div>
            <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <b-row>
                        <b-col sm="8">
                            <base-input v-model="destination.name" name="name" label="Destination Name">
                            </base-input>
                        </b-col>
                        <b-col sm="2">
                            <label class="form-control-label" style="opacity: 0;"> Close </label>
                            <base-button type="secondary" @click="showModal = false">Close</base-button>
                        </b-col>
                        <b-col sm="2">
                            <label class="form-control-label" style="opacity: 0;"> Save </label>
                            <base-button type="primary" @click="onSubmit">Save</base-button>
                        </b-col>
                    </b-row>

                </b-form>

            </validation-observer>
        </div>
        <template #footer>
            <div></div>
        </template>
    </modal>
</template>
<script>
import store from './store';
export default {
    data() {
        return {
            title: '',
            showModal: false,
            destination: this.default(),
        }
    },
    methods: {
        onSubmit() {
            store.dispatch('addDestination', this.destination).then((response) => {
                this.toastSuccess('Successfully');
                this.hide();
                this.destination = this.default();

                this.$emit('force-select-destination', {
                    destination: response.data
                })
            })
        },
        show() {
            this.showModal = true;
        },
        hide() {
            this.showModal = false;
        },
        default() {
            return {
                name: ''
            };
        },
        onModalClose() {
            this.hide();
        }
    }
}
</script>