import CustomerRequest from '@requests/customer_request';
import OperationRequest from '@requests/operation_request';
import OrderItemRequest from '@requests/order_item_request';
import DriverRequest from '@requests/driver_request';
import BranchRequest from '@requests/branch_request';
import CategoryRequest from '@requests/category_request';
import DestinationRequest from '@requests/destination_request';

export default {
    download(_, filter) {
        OrderItemRequest.download(filter);
    },
    index({ commit }, filter) {
        OrderItemRequest.index(filter)
                        .then((data) => {
                            commit('SET_ENTITIES', data);
                        });
    },
    downloadInvoice(_, id) {
        return OrderRequest.downloadInvoice(id);
    },
    getOperations({ commit }) {
        OperationRequest.index().then(({ data }) => {
            commit('SET_OPERATIONS', data);
        });
    },
    getCustomers({ commit }, filter) {
        CustomerRequest.index(filter).then(({ data }) => {
            commit('SET_CUSTOMERS', data);
        });
    },
    getDrivers({ commit }, filter) {
        DriverRequest.index(filter)
                    .then(({ data }) => {
                        commit('SET_DRIVERS', data);
                    });
    },
    getBranches({ commit }) {
        BranchRequest.index()
                    .then(({ data }) => {
                        commit('SET_BRANCHES', data);
                    });
    },
    update(_, orderItem) {
        return OrderItemRequest.update(orderItem.id, orderItem);
    },
    store(_, orderItem) {
        return OrderItemRequest.store(orderItem);
    },
    destroy(_, entity) {
        return OrderItemRequest.destroy(entity.id);
    },
    getPaidOptions({ commit }) {
        OrderItemRequest.getPaidOptions().then(({ data }) => {
            commit('SET_PAID_OPTIONS', data);
        });
    },
    getCategories({ commit }) {
        CategoryRequest.index().then(({data}) => {
            commit('SET_CATEGORIES', data)
        })
    },
    addDestination(_, data) {
        return DestinationRequest.store(data);
    }
}